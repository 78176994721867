<nav class="navbar navbar-expand-lg static-top bg-company-red">
    <a [style.padding-left.px]="getMargin()" aria-hidden="true"><img alt="USC Logo  - Click for Home Page"
            src="../../assets/USC_primary_monogram_svg.svg"></a>
</nav>

<nav class="navbar navbar-expand-lg">
    <div style="display: flex; align-items: center;">
        <h1 *ngIf="studentName" [style.padding-left.px]="getMargin()" class="navbar-brand navLabel">
            STARS Report
            for <br *ngIf="screenWidth < 768" /> {{studentName}}
        </h1>
        <span [style.margin-left.px]="screenWidth < 768 ? 50 : 550" [style.font-weight]="400" *ngIf="studentName"
            class="navLabel">
            ID#: {{uscid}}
        </span>
    </div>
</nav>

<br />
<a *ngIf="!advisor" [style.padding-left.px]="getMargin()" [href]="returnToOasisURL">Back to OASIS</a>



<div class="container">
    <div style="position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
 <!-- Print Button -->
 <div style="text-align: center;">
    <button (click)="printReport()" class="btn btn-primary" style="background-color: #990000; color: white; font-size: 16px; border: none;">
        Print Report
    </button>
</div>



    <div *ngIf="degrees" style="text-align: center;margin-top: 80px;margin-bottom: 50px;">
        <mat-form-field appearance="outline" [style.width.px]=300>
            <mat-select [value]="selectedDegree" placeholder="{{placeholder}}">
                <mat-option *ngIf="advisor" (click)="getTCR()" value="TCR">TCR</mat-option>
                <mat-optgroup label="Degrees">
                    <mat-option (click)="select(degree)" *ngFor="let degree of degrees" [value]="degree">
                        {{degree.degreeProgramCode}} {{degree.degreeProgramDescription}}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </div>

    <div style="text-align: center;">

        <p style="margin-top: 50px;" *ngIf="!found">No data found</p>

        <div *ngIf="report_with_hover">

            <p style="margin-top: 50px;margin-bottom: 20px;">You may directly access the
                following
                sections in the STARS
                report</p>
            <br />

            <ul>
                <li *ngIf="reportContains('pertinent')"><a href=" javascript:void(0);" (click)="scroll('pertinent')"
                        attr.aria-label="Pertinent Data Section">Pertinent
                        Data
                        Section</a></li>
                <li *ngIf="reportContains('major')"><a href="javascript:void(0);" (click)="scroll('major')"
                        attr.aria-label="Major Requirements">Major
                        Requirements</a></li>
                <li *ngIf="reportContains('legend')"><a href="javascript:void(0);" (click)="scroll('legend')"
                        attr.aria-label="Legend">Legend</a></li>
                <li *ngIf="reportContains('courses')"><a href="javascript:void(0);" (click)="scroll('courses')"
                        attr.aria-label="Courses With Missing Grades, Incompletes & In Progress Grading">Courses With
                        Missing
                        Grades, Incompletes
                        & In <br /> Progress Grading</a></li>
                <li *ngIf="reportContains('current')"><a href="javascript:void(0);" (click)="scroll('current')"
                        attr.aria-label="Current Registration">Current
                        Registration</a></li>
                <li *ngIf="reportContains('physical')"><a href="javascript:void(0);" (click)="scroll('physical')"
                        attr.aria-label="Physical Education Units">Physical
                        Education Units</a></li>
                <li *ngIf="reportContains('other')"><a href="javascript:void(0);" (click)="scroll('other')"
                        attr.aria-label="Other courses in your academic account">Other courses in your
                        academic account</a></li>
                <li *ngIf="reportContains('degree')"><a href="javascript:void(0);" (click)="scroll('degree')"
                        attr.aria-label="Units Required for Degree">Units
                        Required for Degree</a></li>
                <li *ngIf="reportContains('gpa')"><a href="javascript:void(0);" (click)="scroll('gpa')"
                        attr.aria-label="University and Major GPA">University
                        and
                        Major GPA</a></li>
                <li *ngIf="reportContains('minor')"><a href="javascript:void(0);" (click)="scroll('minor')"
                        attr.aria-label="Minor Requirements">Minor
                        Requirements</a></li>
                <li *ngIf="reportContains('general')"><a href="javascript:void(0);" (click)="scroll('general')"
                        attr.aria-label="General Education Requirements">General Education
                        Requirements</a></li>
                <li *ngIf="reportContains('skill')"><a href="javascript:void(0);" (click)="scroll('skill')"
                        attr.aria-label="Skill Level and Pre-Major Requirements">Skill Level and Pre-Major
                        Requirements</a></li>
            </ul>

            <br />
            <p style="color: blue" aria-label="Complete">The Blue text in the STARS report identifies the degree
                requirements
                completed.</p>
            <p style="color:#E0182D" aria-label="Incomplete">The Red text in the report highlights the outstanding
                requirements needed in order
                to graduate.</p>
            <p>Place the mouse pointer over the underline codes in the report to view its
                description.</p>
            <p>Descriptions will appear in the status bar at the
                bottom of your browser window.</p>
        </div>
        <br />
    </div>

    <pre>
        <hr  *ngIf="report_with_hover"/>
        <div role="text" [style.color]="getColor(r)" *ngFor="let r of report_with_hover">
            <span [innerHTML]="r" [id]="getId(r)" [attr.aria-hidden]="getAriaHidden(r)" [attr.aria-label]="getAriaLabel(r)">{{r}}</span>
        </div>
    </pre> 

   

    <iframe *ngIf="sanitizedUrl" [src]="sanitizedUrl" width="100%" height="4000"></iframe>

</div>
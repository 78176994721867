import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'pm-root',
  templateUrl: './app.component.html',
  styleUrls: ['shared/shared.css']
})
export class AppComponent {

  @Input() shibUscId: string;

  constructor(elm: ElementRef) {
    this.shibUscId = elm.nativeElement.getAttribute('shibUscID');

    // Store user ten digit id.
    localStorage.setItem('shibUscId', this.shibUscId);
  }
}
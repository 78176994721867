import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient) { }

    async getAuthenticatedUserId(): Promise<string> {
        //works for shib
        const userId = this.http.get(`${environment.backendServiceUrl}/api`, { responseType: 'text' }).toPromise();
        return userId;
    }

    async getCurrentUserId() {
        //works for shib and local
        let uscUSCID: string;
        if (environment.node_env === 'local') {
            uscUSCID = localStorage.getItem('shibUscId');
        } else {
            uscUSCID = await this.getAuthenticatedUserId();
        }
        return uscUSCID;
    }

    isAuthorized(emplid: any, dept: any): Observable<string> {
        return this.http.get<string>(environment.backendServiceUrl + '/api/auth/?dept=' + dept + '&emplid=' + emplid);
    }
}
import { Component, Inject, Input, OnInit } from '@angular/core';
import { StarsService } from './stars.service';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { AuthenticationService } from 'src/auth/authentication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { error } from 'console';

@Component({
  selector: 'pm-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['../shared/shared.css']
})
export class StarsComponent implements OnInit {

  degrees: any;
  report: any;
  report_with_hover: any;
  loading: boolean;
  color: any;
  checkNextBlue: boolean;
  checkNextRed: boolean;
  studentName: any;
  selectedDegree: any;
  found: boolean = true;
  pid: any;
  uscid: any;
  emplid: any;
  employeeId: any;
  @Input() shibUscId: string;
  sanitizedUrl: SafeResourceUrl;
  advisor: boolean;
  dontAnchorify: boolean;
  placeholder: any;
  screenWidth: any;
  returnToOasisURL: any;

  constructor(private service: StarsService, private route: ActivatedRoute, private location: Location, @Inject(DOCUMENT) private document: Document, private authenticationService: AuthenticationService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;

    //mask id from url
    //this.location.replaceState("/");
    //get USCID from URL
    this.route.params.subscribe(params => {
      //this is for advisors, advise usc and student success hub
      if (params.encrypteduscid != undefined) {
        this.advisor = true;
        this.placeholder = "Select an option"
        //get actual uscid from encrypteduscid
        this.service.decryptID(params.encrypteduscid).subscribe({
          next: uscid => {
            this.employeeId = '0000000'
            this.populate(uscid)
          },
          error: err => {
            this.loading = false;
            this.found = false;
          }
        })
      }
    })
    //this is from oasis
    this.route.queryParams.subscribe(params => {
      if (params['oasis-token'] != undefined && params['nonce'] != undefined) {
        this.placeholder = "Select a degree";
        this.returnToOasisURL = params['returnurl'];
        this.service.decryptData(params['oasis-token'].replace(/ /g, '+'), params['nonce'].replace(/ /g, '+')).subscribe({
          next: data => {
            this.employeeId = data.EmployeeId
            this.populate(data.UscId)
          },
          error: err => {
            this.loading = false;
            this.found = false;
          }
        })
      }
    })

  }

  getMargin() {
    if (this.screenWidth < 768) {
      return "10";
    }
    else {
      return "270";
    }
  }

  populate(uscid: any) {
    this.loading = true;
    this.uscid = uscid;
    //get PID from USCID
    this.service.getPID(uscid).subscribe({
      next: student => {
        if (student && student.length > 0) {
          //get Degrees from PID
          this.studentName = student[0][1];
          this.pid = student[0][0];
          this.service.getDegrees(this.uscid).subscribe(degrees => {
            this.loading = false;
            if (degrees) {
              this.degrees = degrees;
              //if only 1 degree found, then render it by default
              if (this.degrees.length == 1) {
                this.select(this.degrees[0]);
              }
            }
            else {
              this.found = false;
            }
          })
        }
        else {
          this.loading = false;
          this.found = false;
        }
      },
      error: err => {
        this.loading = false;
        this.found = false;
      }
    })
  }

  getTCR() {
    this.service.encryptData(this.uscid, this.pid, this.employeeId).subscribe(tcr => {
      //this.document.location.href = tcr;      
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(tcr);
      this.report_with_hover = null;
    })
  }

  /* select(selectedDegree: any) {
    console.log("Select function called with degree:", selectedDegree); // Add this line

    this.dontAnchorify = false;
    this.sanitizedUrl = null;
    this.selectedDegree = selectedDegree;
    this.service.getReport(this.uscid, selectedDegree.degreeProgramCode).subscribe(report => {
      if (!report) {
        this.found = false;
        return;
      }
      this.report = report;
      this.report_with_hover = new Array()
      for (let r of this.report) {
        r = r.darout;
        if (r.trim().startsWith('****  LEGEND'))
          this.dontAnchorify = true;

        if (!this.dontAnchorify) {

          if (r.includes('>IP'))
            r = r.replace(/>IP(?!=)/g, '<a title=">IP -- This course is still in progress" href="javascript: void(0)">>IP</a>')
          else if (r.includes('>P*'))
            r = r.replace('>P*', '<a href="javascript: void(0)">>P*</a>')
          else if (r.includes('>P'))
            r = r.replace('>P', '<a href="javascript: void(0)">>P</a>')
          else if (r.includes('>R'))
            r = r.replace('>R', '<a title=">R -- You have taken this course more than once." href="javascript: void(0)">>R</a>')
          else if (r.includes('>D'))
            r = r.replace('>D', '<a title=">D -- Course & units not accepted but grade was used in GPA calculation." href="javascript: void(0)">>D</a>')

          if (r.trim().startsWith('20'))
            r = r.replace(/(20\d{3})/g, (match, year) => {
              return `<a title='${this.getDesc(year)}' href='javascript: void(0)'>${year}</a>`;
            });
          if (r.trim().startsWith('19'))
            r = r.replace(/(19\d{3})/g, (match, year) => {
              return `<a title='${this.getDesc(year)}' href='javascript: void(0)'>${year}</a>`;
            });
        }

        this.report_with_hover.push(r)
        console.log("Found value (report retrieved):", this.found);
      }
      (error) => {
        console.error("Error fetching report:", error); // Log the error
        this.found = false; // Set found to false in case of an error
        console.log("Found value (error occurred):", this.found); // Log when an error occurs
      }
    });


  } */
    
    select(selectedDegree: any) {
      console.log("Select function called with degree:", selectedDegree); // Log the selected degree
    
      this.dontAnchorify = false;
      this.sanitizedUrl = null;
      this.selectedDegree = selectedDegree;
    
      this.service.getReport(this.uscid, selectedDegree.degreeProgramCode).subscribe({
        next: (report) => {
        // Handle successful report retrieval
          if (!report) {
            this.found = false;
            return;
          }
    
          this.found = true; // Set found to true when report is found
          this.report = report;
          this.report_with_hover = []; // Initialize as an empty array
    
          for (let r of this.report) {
            r = r.darout;
            if (r.trim().startsWith('****  LEGEND')) {
              this.dontAnchorify = true;
            }
    
            if (!this.dontAnchorify) {
              // Replace specific patterns in the report string
              if (r.includes('>IP')) {
                r = r.replace(/>IP(?!=)/g, '<a title=">IP -- This course is still in progress" href="javascript: void(0)">>IP</a>');
              } else if (r.includes('>P*')) {
                r = r.replace('>P*', '<a href="javascript: void(0)">>P*</a>');
              } else if (r.includes('>P')) {
                r = r.replace('>P', '<a href="javascript: void(0)">>P</a>');
              } else if (r.includes('>R')) {
                r = r.replace('>R', '<a title=">R -- You have taken this course more than once." href="javascript: void(0)">>R</a>');
              } else if (r.includes('>D')) {
                r = r.replace('>D', '<a title=">D -- Course & units not accepted but grade was used in GPA calculation." href="javascript: void(0)">>D</a>');
              }
    
              // Link years for specific formats
              if (r.trim().startsWith('20')) {
                r = r.replace(/(20\d{3})/g, (match, year) => {
                  return `<a title='${this.getDesc(year)}' href='javascript: void(0)'>${year}</a>`;
                });
              }
              if (r.trim().startsWith('19')) {
                r = r.replace(/(19\d{3})/g, (match, year) => {
                  return `<a title='${this.getDesc(year)}' href='javascript: void(0)'>${year}</a>`;
                });
              }
            }
    
            this.report_with_hover.push(r);
            
          }
        },
        error: (error) => {
          console.error("Error fetching report:", error); // Log the error
          this.found = false; // Set found to false in case of an error
          console.log("Found value (error occurred):", this.found); // Log when an error occurs
        }
      });
    }
    

  getAriaHidden(r) {
    if (r.trim().startsWith('___') || r.trim().startsWith('==='))
      return true;
    if (r.trim().startsWith('***') && !r.includes('EXCEPTION CODES') && !r.includes("END OF ANALYSIS") && !r.includes("LEGEND"))
      return true;

    return false;
  }

  getAriaLabel(r) {
    if (r.trim().startsWith('****  LEGEND')) return "LEGEND";
    if (r.includes('EXCEPTION CODES')) return "EXCEPTION CODES";
    if (r.includes("END OF ANALYSIS")) return "END OF ANALYSIS";

    return r;
  }

  getDesc(str) {
    let last = str[str.length - 1];
    let term;
    if (last == 1) term = "Spring Term "
    else if (last == 2) term = "Summer Term "
    else if (last == 3) term = "Fall Term "
    return str + " -- " + term + str.substring(0, 4)
  }

  scroll(id) {
    document.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  getId(r) {
    if (r.includes('PERTINENT DATA SECTION'))
      return "pertinent"
    else if (r.includes('****  LEGEND'))
      return "legend"
    else if (r.includes('PHYSICAL EDUCATION'))
      return "physical"
    else if (r.includes('DEGREE COMPLETION'))
      return "degree"
    else if (r.includes('GPA REQUIRED'))
      return "gpa"
    else if (r.includes('GENERAL EDUCATION'))
      return "general"
    else if (r.includes('MAJOR REQUIREMENTS'))
      return "major"
    else if (r.includes('CURRENT REGISTRATION:'))
      return "current"
    else if (r.includes('OTHER COURSES IN YOUR ACADEMIC ACCOUNT'))
      return "other"
    else if (r.includes('COURSES WITH MISSING GRADES'))
      return "courses"
    else if (r.includes('MINOR REQUIREMENTS'))
      return "minor"
    else if (r.includes('OPTION REQUIREMENTS'))
      return "skill"
  }

  reportContains(id: any) {
    return document.getElementById(id);
  }

  getColor(r: string) {
    let temp = r.trim();
    if (temp.includes('=')) return;
    if (temp.startsWith('OK')) {
      this.checkNextBlue = true;
      return "blue";
    }
    if (this.checkNextBlue) {
      this.checkNextBlue = false;
      if (!temp.startsWith('EARNED')) {
        return "blue";
      }
    }
    if (temp.startsWith('IP+') || (temp.startsWith('+'))) {
      return "blue";
    }

    if (temp.startsWith('NO     ')) {
      this.checkNextRed = true;
      return "#E0182D";
    }
    if (this.checkNextRed) {
      this.checkNextRed = false;
      if (!temp.trim().startsWith('EARNED') && !temp.startsWith('**')) {
        return "#E0182D";
      }
    }
    if ((temp.startsWith('-') || temp.startsWith('IP-')) && !temp.includes('>')) {
      return "#E0182D";
    }
  }
  printReport(): void {
    window.print();
  }
  


}
